import './AppointmentFormStyles.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Button, Grid, Stack, TextField, Select, MenuItem } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// let DoctorSelect = 'https://egl-pds-api-stage.herokuapp.com/api/Doctor';
// let BranchOfficeSelect = 'https://egl-pds-api-stage.herokuapp.com/api/BranchOffice';
// let ServiceListSelect = 'https://egl-pds-api-stage.herokuapp.com/api/Service';
let DataAppoinment = 'https://egl-pds-api-stage.herokuapp.com/api/Appointment';

export function RegisterAppoinment() {
    const {
        register,
        reset,
        formState: { errors }
    } = useForm();
    console.log(errors);
    const [optionsDoctors, setOptionsDoctors] = useState([]);
    const [optionsBranch, setOptionsBranch] = useState([]);
    const [optionsService, setOptionsService] = useState([]);
    const [client, setClient] = useState(null);
    const [message, setMessage] = useState("");
    const { user } = useAuth0();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [doctorId, setDoctorId] = useState("");
    const [serviceId, setServiceId] = useState("");
    const [branchOfficeId, setBranchOfficeId] = useState("830857b1-6830-496e-a9f8-842032f3d258");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [userRole, setUserRole] = useState("Admin");
    const [branchOfficesURL, setBranchOfficesURL] = useState("");
    const [doctorsURL, setDoctorsURL] = useState("");
    const [description, setDescription] = useState("");
    const [appointmentStatus, setAppointmentStatus] = useState(0);


    let _dataBranchOffices;
    let _dataDoctors;
    let _dataServices;

    const _branchOfficeId = "830857b1-6830-496e-a9f8-842032f3d258";

    function getURLs() {

        _dataServices = 'https://egl-pds-api-stage.herokuapp.com/api/Service';

        if (userRole != "Admidn") {
            _dataBranchOffices = 'https://egl-pds-api-stage.herokuapp.com/api/BranchOffice/' + _branchOfficeId;
            _dataDoctors = 'https://egl-pds-api-stage.herokuapp.com/api/Doctor/GetDoctorsByBranchOffice/' + _branchOfficeId;
        }
        else {
            _dataBranchOffices = 'https://egl-pds-api-stage.herokuapp.com/api/BranchOffice';
            _dataDoctors = 'https://egl-pds-api-stage.herokuapp.com/api/Doctor';
        }
    }


    function getDoctors(url) {
        axios
            .get(url)
            .then((response) => {
                setOptionsDoctors(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function getBranchOffices(url) {
        axios
            .get(url)
            .then((response) => {
                setOptionsBranch(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function getServices(url) {
        axios
            .get(url)
            .then((response) => {
                setOptionsService(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }


    useEffect(() => {
        const fetchOptions = async () => {
            try {
                await getURLs();
                await getDoctors(_dataDoctors);
                await getBranchOffices(_dataBranchOffices);
                await getServices(_dataServices);
            } catch (error) {
                console.log(error);
            }
        };
        fetchOptions();
    }, []);


    // useEffect(() => {
    //     try {
    //         getURLs();
    //         getDoctors(_dataDoctors);
    //         getBranchOffices(_dataBranchOffices);
    //         getServices(_dataServices);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }, [])

    // useEffect(() => {
    //     const fetchOptions = async () => {
    //         try {
    //             let responseDoctor = await axios.get(DoctorSelect);
    //             setOptionsDoctors(responseDoctor.data);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     };
    //     fetchOptions();
    // }, []);

    // useEffect(() => {
    //     const fetchOptions = async () => {
    //         try {
    //             let responseBranch = await axios.get(BranchOfficeSelect);
    //             setOptionsBranch(responseBranch.data);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     };
    //     fetchOptions();
    // }, []);

    // useEffect(() => {
    //     const fetchOptions = async () => {
    //         try {
    //             let responseService = await axios.get(ServiceListSelect);
    //             setOptionsService(responseService.data);
    //         } catch (error) {
    //             toast.error('Upss! Ocurrio un error.', {
    //                 position: "top-center",
    //                 autoClose: 5000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //             });
    //             console.log(error);
    //         }
    //     };
    //     fetchOptions();
    // }, []);

    // useEffect(() => {
    //     setTimeout(() => setClient({}), 1000);
    // }, []);

    // useEffect(() => {
    //     reset(client);
    // }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const Appointment = {
                userId: user.sub,
                name: name,
                phone: mobileNumber,
                email: email,
                doctorId: doctorId,
                branchOfficeId: branchOfficeId,
                description: description,
                appointmentStatus: appointmentStatus,
                serviceId: serviceId,
                startDate: startDate,
                endDate: endDate
            };
            const res = await fetch(DataAppoinment, {
                method: "POST",
                headers: {
                    'Accept': 'application/json, text/plain',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                body: JSON.stringify(Appointment),
            });
            const resJson = await res.json();
            console.log(resJson);
            if (resJson === "success") {
                setName("");
                setEmail("");
                setMobileNumber("");
                setBranchOfficeId("");
                setDoctorId("");
                setServiceId("");
                setStartDate("");
                setEndDate("");
                setDescription("");
                setAppointmentStatus("");
                toast.success('Se creó la cita exitosamente', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            } else {
                toast.error('Upss! Ocurrio un error al momento de guardar.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Upss! Ocurrio un error.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <>
            <Grid className="CreateAppointment" xs={12} md={6} >

                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                                <TextField
                                    type="text"
                                    label="Nombre de Paciente"
                                    className="form-control is-invalid"
                                    value={name}
                                    name="name"
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                                <TextField
                                    type="text"
                                    value={email}
                                    placeholder="Correo Electrónico"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                                <TextField
                                    type="tel"
                                    label="Teléfono"
                                    value={mobileNumber}
                                    name="mobileNumber"
                                    onChange={(e) => setMobileNumber(e.target.value)}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                                <Select
                                    value={branchOfficeId}
                                    placeholder="Sucursal"
                                    onChange={(e) => setBranchOfficeId(e.target.value)}
                                >
                                    {optionsBranch.map((option) => (
                                        <MenuItem value={option.id}>{option.text}</MenuItem>
                                    ))}
                                </Select>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                                <Select
                                    value={doctorId}
                                    placeholder="Especialista"
                                    onChange={(e) => setDoctorId(e.target.value)}
                                >
                                    {optionsDoctors.map((option) => (
                                        <MenuItem value={option.id}>{option.text}</MenuItem>
                                    ))}
                                </Select>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                                <Select
                                    value={serviceId}
                                    placeholder="Servicio"
                                    onChange={(e) => setServiceId(e.target.value)}
                                >
                                    {optionsService.map((option) => (
                                        <MenuItem value={option.id}>{option.text}</MenuItem>
                                    ))}
                                </Select>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Stack spacing={1}>
                                <TextField
                                    type="textarea"
                                    label="Comentarios"
                                    className="form-control is-invalid"
                                    value={description}
                                    name="description"
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                                <TextField
                                    type="datetime-local"
                                    value={startDate}
                                    placeholder="Hora de inicio"
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                                <TextField
                                    type="datetime-local"
                                    value={endDate}
                                    placeholder="Hora final"
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Stack spacing={1}>
                                <Button variant="contained" type="submit" className="btn btn-primary mr-1">
                                    Guardar
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                    <div className="message">{message ? <p>{message}</p> : null}</div>
                </form>
            </Grid>


            <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                    {!user && (
                        <div className="text-center p-3">
                            <span className="spinner-border spinner-border-lg align-center"></span>
                        </div>
                    )}
                </Stack>
            </Grid>
            <ToastContainer />
        </>
    );
}

export default RegisterAppoinment;
