import React from 'react';
import axios from 'axios';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

export default class BranchOfficeList extends React.Component {
    state = {
        branch: []
    };

    componentDidMount() {
        axios.get(`https://egl-pds-api-stage.herokuapp.com/api/BranchOffice`).then((res) => {
            const branch = res.data;
            this.setState({ branch });
        });
    }

    render() {
        return (
            <>
                <TextField select value={this.state.value}>
                    {this.state.branch.map((branch) => (

                        <MenuItem value={branch.id}>
                            {branch.text}
                        </MenuItem>
                    ))}
                </TextField>
            </>
        );
    }
}
