import reportsMenu from './reportsMenu.js'
import utilities from './utilities';
import branchOffices from './branchOffices.js';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [utilities, branchOffices, reportsMenu]
};

export default menuItems;
