import React from 'react';
import axios from 'axios';
// import MenuItem from '@mui/material/MenuItem';
// import { Select } from '@mui/material';

export default class ServiceList extends React.Component {
    state = {
        services: []
    };

    componentDidMount() {
        axios.get(`https://egl-pds-api-stage.herokuapp.com/api/Service`).then((res) => {
            const services = res.data;
            this.setState({ services });
        });
    }

    render() {
        return (
            <>
                <select labelId="serviceId" label="Servicio" value={this.state.value}>
                    {this.state.services.map((services) => (
                        <options value={services.id}>{services.text}</options>
                    ))}
                </select>
            </>
        );
    }
}
