//import { useEffect, useState } from 'react';
import * as React from 'react';
// import axios from 'axios';
import PropTypes, { number } from 'prop-types';
import { IMaskInput } from 'react-imask';

// material-ui
import { Button, Grid, InputLabel, OutlinedInput, Stack } from '@mui/material';
import AnimateButton from 'components/@extended/AnimateButton';
import TextField from '@mui/material/TextField';

// Components
import DoctorList from './DoctorList';
import ServiceList from './ServicesList';
import BranchOfficeList from './BranchOfficeList';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="(#00) 000-0000"
            definitions={{
                '#': /[1-9]/
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

TextMaskCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

const RegisterAppoinment = () => {
    const [formValue, setformValue] = React.useState({
        name: '',
        phone: '',
        email: '',
        doctorId: '',
        branchOfficeId: '',
        serviceId: '',
        startDate: number,
        endDate: number,
        textmask: '(000) 000-0000',
        numberformat: '1320'
        //submit: null
    });
    const handleSubmit = async () => {
        // const AppFormData = new FormData();
        // AppFormData.append('name', formValue.name);
        // AppFormData.append('phone', formValue.phone);
        // AppFormData.append('email', formValue.email);
        // AppFormData.append('doctorid', formValue.doctorId);
        // AppFormData.append('serviceid', formValue.serviceId);
        // AppFormData.append('branchOfficeId', formValue.branchOfficeId);
        // AppFormData.append('startdDate', formValue.startDate);
        // AppFormData.append('endDate', formValue.endDate);
        console.log(formValue.name);
        try {
            fetch('https://egl-pds-api-stage.herokuapp.com/api/Appointment/', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: 'something',
                    phone: 'somethingElse',
                    email: '',
                    doctorId: '',
                    serviceId: '',
                    branchOfficeId: '',
                    startDate: '',
                    endDate: ''
                })
            });
            // axios({
            //     method: 'post',
            //     url: 'https://egl-pds-api-stage.herokuapp.com/api/Appointment',
            //     data: formValue,
            //     headers: { 'Content-Type': 'multipart/form-data' }

            // })
            //     .then(function (response) {
            //         console.log(response);
            //     });

        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = (event) => {
        setformValue({
            ...formValue,
            [event.target.name]: event.target.value
        });
    };

    return (
        <>
            <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                            <InputLabel htmlFor="name">Nombre del Paciente*</InputLabel>
                            <OutlinedInput
                                id="name"
                                type="name"
                                value={formValue.name}
                                name="name"
                                // onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                            />

                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                            <InputLabel htmlFor="phone">Telefono</InputLabel>
                            <OutlinedInput
                                value={formValue.phone}
                                onChange={handleChange}
                                name="phone"
                                id="phone"
                                inputComponent={TextMaskCustom}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                            <InputLabel htmlFor="email">Correo Electronico *</InputLabel>
                            <OutlinedInput
                                fullWidth
                                id="email"
                                type="email"
                                value={formValue.email}
                                name="email"
                                // onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{}}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                            <InputLabel htmlFor="doctorId">Doctor</InputLabel>
                            <DoctorList id="doctorId" value={formValue.doctorId} onChange={handleChange} />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                            <InputLabel id="serviceId">Servicio</InputLabel>
                            <ServiceList />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                            <InputLabel id="branchOfficeId">Sucursal</InputLabel>
                            <BranchOfficeList />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                            <TextField type="datetime-local" value={formValue.startDate} onChange={handleChange}></TextField>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                            <TextField type="datetime-local" value={formValue.endDate} onChange={handleChange}></TextField>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <AnimateButton>
                            <Button disableElevation fullWidth size="large" type="submit" variant="contained" color="primary">
                                Crear Cita
                            </Button>
                        </AnimateButton>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default RegisterAppoinment;
