import React from 'react';
import notify from 'devextreme/ui/notify';
import CustomStore from 'devextreme/data/custom_store';
import moment from "moment";
import 'moment-timezone';
// import { dataAppointmentStatus } from './Data/appointmentStatus.js';
import 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import {
    Column,
    DataGrid,
    FilterRow,
    HeaderFilter,
    GroupPanel,
    Scrolling,
    Editing,
    Grouping,
    Lookup,
    MasterDetail,
    Summary,
    RangeRule,
    RequiredRule,
    StringLengthRule,
    GroupItem,
    TotalItem,
    ValueFormat,
} from 'devextreme-react/data-grid';
import DateBox from 'devextreme-react/date-box';

import { withAuth0 } from '@auth0/auth0-react';


const dataAppointments = 'https://egl-pds-api-stage.herokuapp.com/api/Appointment/830857b1-6830-496e-a9f8-842032f3d258';
const dataAppointments2 = 'https://egl-pds-api-stage.herokuapp.com/api/Appointment/830857b1-6830-496e-a9f8-842032f3d258';

const dataDoctors = 'https://egl-pds-api-stage.herokuapp.com/api/Doctor';
const dataBranchOffices = 'https://egl-pds-api-stage.herokuapp.com/api/BranchOffice';
const dataServices = 'https://egl-pds-api-stage.herokuapp.com/api/Service';

const branchOfficeGroups = ['branchOfficeId'];
const groups = ['branchOfficeId', 'doctorId'];

const serviceUrl = "https://egl-pds-api-stage.herokuapp.com/api/Appointment/GetAllAppointments";

let _startDate;
let _endDate;
let _branchOffice;

const idSucursalConstitucion = 'a593e064-8ff9-467d-b0f9-d779baa54eb6';
const idSucursalVinedos = '830857b1-6830-496e-a9f8-842032f3d258';
const idSucursalNavarro = '75a23d0e-7d8d-4114-8a2e-d7c1c89ec9eb';
const idSucursalRevolucion = '1e30df0f-40f3-427a-a132-4918d0554c92';

const store = new CustomStore({
    key: 'id',
    load: (loadOptions) => {
        console.log(_startDate);
        if (_startDate == null || _startDate == undefined) {
            _startDate = moment().startOf('month').format('YYYY-MM-DDTHH:mm:ssZ').toLocaleString();
        }

        if (_endDate == null || _startDate == undefined) {
            _endDate = moment().endOf('month').format('YYYY-MM-DDTHH:mm:ssZ').toLocaleString()
        }
        console.log("moment" + _startDate);
        console.log("moment" + _endDate);

        return fetch(serviceUrl + '?startDate=' + _startDate + '&endDate=' + _endDate)
            .then(handleErrors)
            .then(response => response.json())
            .catch(() => { throw 'Network error' });
    },
    insert: (values) => {
        return fetch(serviceUrl, {
            method: "POST",
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(values),
        })
            .then(handleErrors);
    },
    update: (key, values) => {
        return fetch(serviceUrl + "/" + key, {
            method: "PUT",
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(values.toString()),
        })
            .then(handleErrors)
    },
    remove: (key) => {
        return fetch(serviceUrl + "/" + key, {
            method: "Delete",
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8'
            }
        })
            .then(handleErrors);
    }
});

const appointmentList = new DataSource({
    load: (loadOptions) => {
        console.log(_startDate);
        if (_startDate == null || _startDate == undefined) {
            _startDate = moment().startOf('month').format('YYYY-MM-DDTHH:mm:ssZ').toLocaleString();
        }

        if (_endDate == null || _startDate == undefined) {
            _endDate = moment().endOf('month').format('YYYY-MM-DDTHH:mm:ssZ').toLocaleString()
        }
        console.log("moment" + _startDate);
        console.log("moment" + _endDate);

        return fetch(serviceUrl + '?startDate=' + _startDate + '&endDate=' + _endDate + '&branchOfficeId=' + _branchOffice)
            .then(handleErrors)
            .then(response => response.json())
            .catch(() => { throw 'Network error' });
    },
    byKey: (key) => {
        // Retrieving a data object by key
    },
    onLoadError: (error) => {
        console.log(error.message);
    }
});
const getAppointments = () => {
    appointmentList.reload();
};

function handleErrors(response) {
    if (!response.ok) {
        console.log("error");
        throw Error(response.statusText);
    } else {
        console.log("success");
    }
    return response;
};
let userId = null;
class AppointmentList extends React.Component {

    constructor(props) {
        super(props);
        this.onStartDateChanged = this.onStartDateChanged.bind(this);
        this.onEndDateChanged = this.onEndDateChanged.bind(this);
        this.state = {
            startDateUI: moment().startOf('month').format('YYYY-MM-DDTHH:mm:ssZ').toLocaleString(),
            endDateUI: moment().endOf('month').format('YYYY-MM-DDTHH:mm:ssZ').toLocaleString(),
        };
    }

    onStartDateChanged(e) {
        this.setState({
            startDateUI: e.value,
        });
        _startDate = e.value;
    };

    onEndDateChanged(e) {
        this.setState({
            endDateUI: e.value,
        });
        _endDate = e.value;
    };

    render() {
        const { user } = this.props.auth0;

        userId = user.sub;
        if (userId === "google-oauth2|103805019871159289380" || userId === "google-oauth2|102684668600914433947" || userId === "auth0|62fa678ae6af282704792b2d" || userId === "auth0|6384cc7cf4edfed30f808906") {
            _branchOffice = '37546936-5330-41ea-8895-209d0c6f8854';
        }

        // Sucursal Viñedos
        if (userId === "auth0|63096b79cb2a7d92d814767e") {
            _branchOffice = idSucursalVinedos;
        }

        // Sucursal Navarro
        if (userId === "auth0|63768e35802804f3214b903b") {
            _branchOffice = idSucursalNavarro;
        }

        // Sucursal Constitución
        if (userId === "auth0|63768f4c911bc568294085a4" || userId === "auth0|6384cc94c0cb508c49df7773") {
            _branchOffice = idSucursalConstitucion;
        }

        // Sucursal Revolución
        if (userId === "auth0|63768fa5ea0961b9a9b77d89") {
            _branchOffice = idSucursalRevolucion;
        }
        return (
            <div>
                <div className="dx-fieldset">
                    <div className="dx-field">
                        <div className="dx-field-label">Fecha de inicio</div>
                        <div className="dx-field-value">
                            <DateBox
                                applyValueMode="useButtons"
                                value={this.state.startDateUI}
                                pickerType="calendar"
                                onValueChanged={this.onStartDateChanged} />
                        </div>
                    </div>
                </div>
                <div className="dx-fieldset">
                    <div className="dx-field">
                        <div className="dx-field-label">Fecha Final</div>
                        <div className="dx-field-value">
                            <DateBox
                                applyValueMode="useButtons"
                                value={this.state.endDateUI}
                                pickerType="calendar"
                                onValueChanged={this.onEndDateChanged} />
                        </div>
                    </div>
                </div>
                <button type="button" onClick={getAppointments}> Buscar</button>

                <DataGrid
                    dataSource={appointmentList}
                    showBorders={true}
                    height={600}
                    remoteOperations={false}
                >
                    <FilterRow visible={true} />
                    <HeaderFilter visible={false} />
                    <GroupPanel visible={false} />
                    <Scrolling mode="virtual" />
                    <Editing
                        mode="row"
                    />
                    <Grouping autoExpandAll={false} />

                    <Column dataField="text" caption="Cliente">

                    </Column>

                    <Column dataField="startDate" dataType="date" caption="Fecha">
                    </Column>

                    <Column
                        dataField="branchOffice"
                        caption="Sucursal"
                    >
                    </Column>
                    <Column
                        dataField="doctor"
                        caption="Podólogo"
                    >
                    </Column>
                    <Column dataField="appointmentStatusName" caption="Estatus">
                    </Column>
                    <GroupItem summaryType="count" />
                </DataGrid>
            </div>
        );




    }
}

function showToast(event, value, type) {
    notify(`${event} "${value}" task`, type, 2000);
    // showToast('Se actualizo exitosamente la cita de', e.oldData.text, 'info');
}


export default withAuth0(AppointmentList);
