export const dataAppointmentStatusAdmin = [
    {
        id: 1,
        text: 'Creada',
        color: '#1e90ff'
    },
    {
        id: 2,
        text: 'Cancelar',
        color: '#f12c60'
    },
    {
        id: 3,
        text: 'Confirmada',
        color: '#1effa5'
    },
    {
        id: 4,
        text: 'No contesto',
        color: '#fff21e'
    },
    {
        id: 5,
        text: 'No llego',
        color: '#ff9747'
    },
    {
        id: 6,
        text: 'Borrar',
        color: '#cb6bb2'
    },
    {
        id: 7,
        text: 'Cambio de fecha',
        color: '#ff9747'
    }

]